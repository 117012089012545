.edb-datepicker .ant-picker-cell-selected .ant-picker-cell-inner {
  background: #0b847f;
  border-radius: 30px;
}

.edb-datepicker .ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 30px;
  border: 1px solid #0b847f;
}

.edb-datepicker .ant-picker-footer .ant-picker-today-btn {
  color: #0b847f;
}
