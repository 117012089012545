.main {
    background-size: cover;
    background-image: url("/src/components/Layout/images/homePageBackground.png");
}

button:focus, a:focus, input:focus {
    box-shadow: 0 0 #0000, 0 0 #0000, 0 0 0 0.1875rem #BD13B8;
    outline: none;
  }

.main::before {
    content: "";
    background: #ffffff;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.8;
}

.skipTo-main {
    padding: 8px;
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
    z-index: 99;
}

.skipTo-main:focus {
    position: static;
    width: 100%;
    height: auto;
    overflow: visible;
    background-color: #BD13B8;
    color: #ffffff
}

.header{
    position: absolute;
    margin: 20px;
    text-align: center;
    width: 200px;
    padding: 5px 0;
}

.header>p {
    font-size: 11px;
    text-align: center;
    text-transform: uppercase;
    font-weight: 700;
}

.home-menu {
    position: relative;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
}

.menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.circle {
    width: 456px;
    height: 456px;
    position: relative;
    border-radius: 50%;
    background: linear-gradient(180deg, rgba(11, 132, 127, 0.8) 59.05%, rgba(11, 132, 127, 0) 100%);
    opacity: 0.8;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.25);
}

.circle-inline {
    position: absolute;
    width: 375px;
    height: 375px;
    left: 40px;
    top: 40px;
    border-radius: 50%;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.25);
    background: radial-gradient(72.45% 72.45% at 27.78% 24.07%, #FFFFFF 0%, #FFFFFF 26.8%, #FFFFFF 57.38%, #C0C1C5 99.26%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
}

.title {
    box-sizing: border-box;
    position: absolute;
    left: 12%;
    bottom: 52%;

    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 700;
    font-size: 55px;
    line-height: 75px;
    color: #0B847F;
    border-bottom: 2px solid #0B847F;
    padding-bottom: 2px;
}

.circle-inline span{
    position: absolute;
    left: 6%;
    top: 50.29%;
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    color: #0B847F;
}

/*******Animation line start********/

.line {
    position: relative;
    left: 223px;
    top: -46px;
    z-index: -11;
}

.animation-line {
    position: absolute;
    top: 158px;
    left: -25px;
    width: 18px;
    height: 18px;
    background: #ffffff;
    box-shadow: 0 0 0 8px rgba(255,255,255, 0.3);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.5s;
    z-index: 9;
}

.animation-line::after {
    content: "";
    position: absolute;
    top: -74px;
    left: -22px;
    border: 1px solid rgb(255,255,255);
    width: 100px;
    transform: rotate(310deg);
    transform-origin: right;
    transition: all 1s;
}

.animation-line-sec {
    position: absolute;
    top: 103px;
    left: -73px;
    width: 18px;
    height: 18px;
    background: #ffffff;
    box-shadow: 0 0 0 8px rgb(255 255 255 / 30%);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.5s;
    z-index: 9;
}

.animation-line-sec::after {
    content: "";
    position: absolute;
    top: -39px;
    left: 6px;
    border: 1px solid rgb(255,255,255);
    width: 117px;
    transform: rotate(338deg);
    transform-origin: right;
}

.animation-line-third {
    position: absolute;
    top: 57px;
    left: -102px;
    width: 18px;
    height: 18px;
    background: #ffffff;
    box-shadow: 0 0 0 8px rgb(255 255 255 / 30%);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.5s;
    z-index: 9;
}

.animation-line-third::after {
    content: "";
    position: absolute;
    top: 8px;
    left: 17px;
    border: 1px solid rgb(255,255,255);
    width: 117px;
}

.animation-line-fourth {
    position: absolute;
    top: 18px;
    left: -71px;
    width: 18px;
    height: 18px;
    background: #ffffff;
    box-shadow: 0 0 0 8px rgb(255 255 255 / 30%);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.5s;
    z-index: 9;
}

.animation-line-fourth::after {
    content: "";
    position: absolute;
    top: 55px;
    left: 8px;
    border: 1px solid rgb(255,255,255);
    width: 117px;
    transform: rotate(-338deg);
    transform-origin: right;
}

.animation-line-fifth {
    position: absolute;
    top: -33px;
    left: -25px;
    width: 18px;
    height: 18px;
    background: #ffffff;
    box-shadow: 0 0 0 8px rgb(255 255 255 / 30%);
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.5s;
    z-index: 9;
}

.animation-line-fifth::after {
    content: "";
    position: absolute;
    top: 91px;
    left: -21px;
    border: 1px solid rgb(255,255,255);
    width: 100px;
    transform: rotate(-310deg);
    transform-origin: right;
}

/*******Animation line start********/

.play {
    cursor: pointer;
    position: absolute;
    left: 40%;
    top: 70%;
    border-radius: 50%;
    width: 65px;
    height: 65px;
    border: 6px solid #0B847F;
    background: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menubar {
    position: relative;
    top: -25px;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 35px;
    width: 460px;
    height: 132px;
    background: linear-gradient(180deg, rgba(142, 144, 147, 0.6) 0%, rgba(142, 144, 147, 0) 100%);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 68px;
    border: none;
    text-align: start;
}

.menubar::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 68px;
    background: linear-gradient(180deg, rgba(11, 132, 127, 0.6) 43.57%, rgba(142, 144, 147, 0) 100%);
    opacity: 0;
    transition: all 0.5s;
}

.menubar:hover .change-color {
    background: #0B847F;
}

.menubar:hover .change-color::after {
    border: 1px solid #0B847F;
}

.menubar:hover::after{
    transition: ease-in-out 1s;
    opacity: 1;
}

.menubar:hover span {
    color: #ffffff;
}

.left {
    left: -90px;
}

.menubar p {
    color: #ffffff;
    font-family: 'Noto Sans Armenian';
    text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    margin-bottom: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 22px;
    line-height: 30px;
}

.sec {
    margin-left: 70px;
}

.third {
    margin-left: 130px;
}

.text {
    z-index: 99;
    margin-left: 10px;
    inline-size: 280px;
    width: 70%;
}

.text span {
    color: #8E8A8A;
    font-family: 'Noto Sans Armenian';
    font-style: normal;
    font-weight: 700;
    font-size: 13px;
    line-height: 14px;
    word-wrap: anywhere;
    transition: all 0.5s;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;
    width: 105px;
    height: 105px;
    margin-left: 12px;
    border-radius: 50%;
    box-shadow: 0 5px 4px rgba(0, 0, 0, 0.25);
    background: linear-gradient(180deg, #FFFFFF 0.49%, #FBFBFB 24.59%, #EEEEEF 49.97%, #D9DADB 75.83%, #BFC1C3 99.36%);
}

.footer {
    position: absolute;
    width: 100%;
    height: 70px;
    background: #c3c3c480;
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
    bottom: 0;
    z-index: 999999;
}

.nork {
    width: 100px;
}

.footer-text {
    color: #000000;
    margin: 0;
}

.img {
    z-index: 999;
    cursor: pointer;
    display: flex;
    align-items: center;
    height: inherit;
    gap: 12px;
}

.all-icons {
    width: fit-content;
    height: 65px;
    display: flex;
    align-items: center;
}

.icons {
    width: 60px;
    height: 60px;
    cursor: pointer;
    margin: 0 5px 0 5px;
    border: 2px solid #ffffff;
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
}

.icons:first-child img {
    width: 80%;
}

.icons img {
    width: 60%;
}

.response-circle {
    display: none;
}

@media screen and (max-width: 1400px) {

    .circle {
        width: 381px;
        height: 381px;
    }

    .circle-inline {
        width: 300px;
        height: 300px;
    }

    .title {
        font-size: 48px;
        left: 8%;
    }

    .circle-inline span {
        left: 0;
    }

    .line {
        height: 480px;
        left: 190px;
    }

    .menubar {
        top: -15px;
        margin-top: 40px;
        height: 100px;
    }

    .menubar p {
        font-size: 20px;
        margin: 0;
    }

    .text span {
        font-size: 11px;
    }

    .animation-line {
        top: 108px;
        left: 0;
    }

    .animation-line-sec {
        top: 76px;
        left: -55px;
    }

    .animation-line-third {
        top: 40px;
        left: -90px;
    }

    .animation-line-fourth {
        top: 5px;
        left: -55px;
    }

    .animation-line-fifth {
        top: -25px;
        left: -8px;
    }

    .icon {
        width: 85px;
        height: 85px;
        margin-left: 8px;
        z-index: 99;
    }
}

@media screen and (max-width: 1024px) {
    .header {
        position: relative;
        padding: 20px;
        margin: 0;
        width: 100%;
    }

    .home-menu {
        padding-bottom: 50px;
    }

    .animation-line,
    .animation-line-sec,
    .animation-line-third,
    .animation-line-fourth,
    .animation-line-fifth,
    .menu{
        display: none;
    }

    .menubar {
        left: 0;
        margin-left: 0;
    }

    .home-menu {
        display: grid;
    }

    .response-circle {
        display: grid;
        justify-content: center;
        background: #f0ffff75;
        border-radius: 20px;
        text-align: center;
    }

    .title {
        font-size: 40px;
        text-align: center;
        position: unset;
    }

    .play {
        position: relative;
        top: 10px;
    }

    .footer {
        bottom: unset;
    }

}

@media screen and (max-width: 865px) {
    .footer-text {
        display: none;
    }

    .icons {
        width: 50px;
        height: 50px;
    }

}

@media screen and (max-width: 500px) {

    .all-menu {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    .menubar {
        width: 85%;
        height: 80%;
    }

    .icons {
        width: 40px;
        height: 40px;
    }

    .menubar p {
        font-size: 20px;
    }

    .icon {
        width: 90px;
        height: 90px;
        margin-left: 0;
    }

    .text {
        font-size: 10px;
        inline-size: 235px;
    }

    .text span {
        font-size: 13px;
    }
}

@media screen and (max-width: 430px) {

    .menubar p {
        font-size: 17px;
    }

    .menubar {
        width: 90%;
    }

    .img {
        bottom: 50px;
    }
}

.header_right {
    position: absolute;
    right: 0;
    top: 0;
    padding: 30px 25px;
}

.header_right img {
    width: 40px;
    object-fit: contain;
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 #0b847f;
    }
    70% {
        box-shadow: 0 0 0 10px rgba(0, 0, 255, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(0, 0, 255, 0);
    }
}

.pulse-tooltip .ant-tooltip-inner {
    animation: pulse 1.5s infinite;
    background: #0b847f;
    color: #ffffff
}

.ant-tooltip-arrow-content:before {
    background: #0b847f !important;
}
