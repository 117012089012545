@import url('https://fonts.googleapis.com/css?family=Noto+Sans+Armenian&display=swap');

body {
  font-family: Noto Sans Armenian, Helvetica Neue, Helvetica, Arial, sans-serif;
}

button:focus, a:focus, input:focus, .accessibility-shadow:focus {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 0 0.1875rem #BD13B8;
  outline: none;
}

button:focus span {
  color: #000000;
}

.ant-picker-focused, .ant-input:focus, .ant-radio-input:focus+.ant-radio-inner {
  box-shadow: 0 0 #0000, 0 0 #0000, 0 0 0 0.1875rem #BD13B8;
}

.edb-strong {
  font-weight: 800;
}

.ant-form-item-explain-error {
  color: #CC0000 !important;
}

.ant-form-item .ant-form-item-explain .ant-form-item-explain-error {
  color: #CC0000 !important;
}

.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
  border-color: #CC0000 !important;
}

.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input {
  border-color: #CC0000 !important;
}

.ant-picker-status-error.ant-picker, .ant-picker-status-error.ant-picker:focus {
  border-color: #CC0000 !important;
}